import { router } from '@/app/routes'
import '@mantine/core/styles.layer.css'
import '@mantine/carousel/styles.css'
import '@mantine/dates/styles.css'
import { AppProvider } from '@/app/main-provider'
import { RouterProvider } from 'react-router-dom'

export const App = () => {
  return (
    <AppProvider>
      <RouterProvider router={router} />
    </AppProvider>
  )
}
