/* 
  Credit: https://github.com/bl00mber/react-phone-input-2/blob/master/src/rawCountries.js
*/

// Country model:
// [
//    Country name,
//    Regions,
//    iso2 code,
//    International dial code,
//    Format (if available),
//    Order priority (if >1 country with same dial code),
//    Area codes (if >1 country with same dial code)
// ]
//
// Regions:
// ['america', 'europe', 'asia', 'oceania', 'africa']
//
// Sub-regions:
// ['north-america', 'south-america', 'central-america', 'carribean',
//  'eu-union', 'ex-ussr', 'ex-yugos', 'baltic', 'middle-east', 'north-africa']

export type PhoneCountry = {
  name: string
  id: string
  code: string
  mask: string[]
}

export const rawCountries: PhoneCountry[] = [
  {
    name: 'Senegal',
    id: 'sn',
    code: '221',
    mask: ['00 000 00 00'],
  },
  {
    name: 'United States',
    id: 'us',
    code: '1',
    mask: ['(000)000 0000'],
  },
  {
    name: 'Afghanistan',
    id: 'af',
    code: '93',
    mask: ['00 000 0000'],
  },
  {
    name: 'Albania',
    id: 'al',
    code: '355',
    mask: ['(000)000 000'],
  },
  {
    name: 'Algeria',
    id: 'dz',
    code: '213',
    mask: ['00 000 0000'],
  },
  {
    name: 'Andorra',
    id: 'ad',
    code: '376',
    mask: ['000 000'],
  },
  {
    name: 'Angola',
    id: 'ao',
    code: '244',
    mask: ['(000)000 000'],
  },
  {
    name: 'Antigua and Barbuda',
    id: 'ag',
    code: '1268',
    mask: ['000 0000'],
  },
  {
    name: 'Argentina',
    id: 'ar',
    code: '54',
    mask: ['(000)000 0000'],
  },
  {
    name: 'Armenia',
    id: 'am',
    code: '374',
    mask: ['00 000 000'],
  },
  {
    name: 'Aruba',
    id: 'aw',
    code: '297',
    mask: ['000 0000'],
  },
  {
    name: 'Australia',
    id: 'au',
    code: '61',
    mask: ['0 0000 0000'],
  },
  {
    name: 'Austria',
    id: 'at',
    code: '43',
    mask: ['(000)000 0000'],
  },
  {
    name: 'Azerbaijan',
    id: 'az',
    code: '994',
    mask: ['00 000 00 00'],
  },
  {
    name: 'Bahamas',
    id: 'bs',
    code: '1242',
    mask: ['000 0000'],
  },
  {
    name: 'Bahrain',
    id: 'bh',
    code: '973',
    mask: ['0000 0000'],
  },
  {
    name: 'Bangladesh',
    id: 'bd',
    code: '880',
    mask: ['00 000 000'],
  },
  {
    name: 'Barbados',
    id: 'bb',
    code: '1246',
    mask: ['000 0000'],
  },
  {
    name: 'Belarus',
    id: 'by',
    code: '375',
    mask: ['(00)000 00 00'],
  },
  {
    name: 'Belgium',
    id: 'be',
    code: '32',
    mask: ['(000)000 000'],
  },
  {
    name: 'Belize',
    id: 'bz',
    code: '501',
    mask: ['000 0000'],
  },
  {
    name: 'Benin',
    id: 'bj',
    code: '229',
    mask: ['00 00 0000'],
  },
  {
    name: 'Bhutan',
    id: 'bt',
    code: '975',
    mask: ['17 000 000', '0 000 000'],
  },
  {
    name: 'Bolivia',
    id: 'bo',
    code: '591',
    mask: ['0 000 0000'],
  },
  {
    name: 'Bosnia and Herzegovina',
    id: 'ba',
    code: '387',
    mask: ['00 00000', '00 0000'],
  },
  {
    name: 'Botswana',
    id: 'bw',
    code: '267',
    mask: ['00 000 000'],
  },
  {
    name: 'Brazil',
    id: 'br',
    code: '55',
    mask: ['(00)0000 0000', '(00)7000 0000', '(00)90000 0000'],
  },
  {
    name: 'British Indian Ocean Territory',
    id: 'io',
    code: '246',
    mask: ['000 0000'],
  },
  {
    name: 'Brunei',
    id: 'bn',
    code: '673',
    mask: ['000 0000'],
  },
  {
    name: 'Bulgaria',
    id: 'bg',
    code: '359',
    mask: ['(000)000 000'],
  },
  {
    name: 'Burkina Faso',
    id: 'bf',
    code: '226',
    mask: ['00 00 0000'],
  },
  {
    name: 'Burundi',
    id: 'bi',
    code: '257',
    mask: ['00 00 0000'],
  },
  {
    name: 'Cambodia',
    id: 'kh',
    code: '855',
    mask: ['00 000 000'],
  },
  {
    name: 'Cameroon',
    id: 'cm',
    code: '237',
    mask: ['0000 0000'],
  },
  {
    name: 'Canada',
    id: 'ca',
    code: '1',
    mask: ['(000)000 0000'],
  },
  {
    name: 'Cape Verde',
    id: 'cv',
    code: '238',
    mask: ['(000)00 00'],
  },
  {
    name: 'Caribbean Netherlands',
    id: 'bq',
    code: '599',
    mask: [],
  },
  {
    name: 'Central African Republic',
    id: 'cf',
    code: '236',
    mask: ['00 00 0000'],
  },
  {
    name: 'Chad',
    id: 'td',
    code: '235',
    mask: ['00 00 00 00'],
  },
  {
    name: 'Chile',
    id: 'cl',
    code: '56',
    mask: ['0 0000 0000'],
  },
  {
    name: 'China',
    id: 'cn',
    code: '86',
    mask: ['(000)0000 0000', '(000)0000 000', '00 00000 00000'],
  },
  {
    name: 'Colombia',
    id: 'co',
    code: '57',
    mask: ['(000)000 0000'],
  },
  {
    name: 'Comoros',
    id: 'km',
    code: '269',
    mask: ['00 00000'],
  },
  {
    name: 'Congo',
    id: 'cd',
    code: '243',
    mask: ['(000)000 000'],
  },
  {
    name: 'Congo',
    id: 'cg',
    code: '242',
    mask: ['00 000 0000'],
  },
  {
    name: 'Costa Rica',
    id: 'cr',
    code: '506',
    mask: ['0000 0000'],
  },
  {
    name: 'Côte d’Ivoire',
    id: 'ci',
    code: '225',
    mask: ['00 000 000'],
  },
  {
    name: 'Croatia',
    id: 'hr',
    code: '385',
    mask: ['00 000 000'],
  },
  {
    name: 'Cuba',
    id: 'cu',
    code: '53',
    mask: ['0 000 0000'],
  },
  {
    name: 'Curaçao',
    id: 'cw',
    code: '599',
    mask: ['000 0000'],
  },
  {
    name: 'Cyprus',
    id: 'cy',
    code: '357',
    mask: ['00 000 000'],
  },
  {
    name: 'Czech Republic',
    id: 'cz',
    code: '420',
    mask: ['(000)000 000'],
  },
  {
    name: 'Denmark',
    id: 'dk',
    code: '45',
    mask: ['00 00 00 00'],
  },
  {
    name: 'Djibouti',
    id: 'dj',
    code: '253',
    mask: ['00 00 00 00'],
  },
  {
    name: 'Dominica',
    id: 'dm',
    code: '1767',
    mask: ['000 0000'],
  },
  {
    name: 'Dominican Republic',
    id: 'do',
    code: '1',
    mask: ['(809)000 0000', '(829)000 0000', '(849)000 0000'],
  },
  {
    name: 'Ecuador',
    id: 'ec',
    code: '593',
    mask: ['00 000 0000', '0 000 0000'],
  },
  {
    name: 'Egypt',
    id: 'eg',
    code: '20',
    mask: ['(000)000 0000'],
  },
  {
    name: 'El Salvador',
    id: 'sv',
    code: '503',
    mask: ['00 00 0000'],
  },
  {
    name: 'Equatorial Guinea',
    id: 'gq',
    code: '240',
    mask: ['00 000 0000'],
  },
  {
    name: 'Eritrea',
    id: 'er',
    code: '291',
    mask: ['0 000 000'],
  },
  {
    name: 'Estonia',
    id: 'ee',
    code: '372',
    mask: ['0000 0000', '000 0000'],
  },
  {
    name: 'Ethiopia',
    id: 'et',
    code: '251',
    mask: ['00 000 0000'],
  },
  {
    name: 'Fiji',
    id: 'fj',
    code: '679',
    mask: ['00 00000'],
  },
  {
    name: 'Finland',
    id: 'fi',
    code: '358',
    mask: ['(000)000 00 00'],
  },
  {
    name: 'France',
    id: 'fr',
    code: '33',
    mask: ['00000 0000', '(000)000 000', '00 0000', '(000)000 000'],
  },
  {
    name: 'French Guiana',
    id: 'gf',
    code: '594',
    mask: ['00000 0000'],
  },
  {
    name: 'French Polynesia',
    id: 'pf',
    code: '689',
    mask: ['00 00 00'],
  },
  {
    name: 'Gabon',
    id: 'ga',
    code: '241',
    mask: ['0 00 00 00'],
  },
  {
    name: 'Gambia',
    id: 'gm',
    code: '220',
    mask: ['(000)00 00'],
  },
  {
    name: 'Georgia',
    id: 'ge',
    code: '995',
    mask: ['(000)000 000'],
  },
  {
    name: 'Germany',
    id: 'de',
    code: '49',
    mask: ['(0000)000 0000', '(000)000 0000', '(000)00 0000', '(000)00 000', '(000)00 00', '000 000'],
  },
  {
    name: 'Ghana',
    id: 'gh',
    code: '233',
    mask: ['(000)000 000'],
  },
  {
    name: 'Greece',
    id: 'gr',
    code: '30',
    mask: ['(000)000 0000'],
  },
  {
    name: 'Grenada',
    id: 'gd',
    code: '1473',
    mask: ['000 0000'],
  },
  {
    name: 'Guadeloupe',
    id: 'gp',
    code: '590',
    mask: [],
  },
  {
    name: 'Guam',
    id: 'gu',
    code: '1671',
    mask: ['000 0000'],
  },
  {
    name: 'Guatemala',
    id: 'gt',
    code: '502',
    mask: ['0 000 0000'],
  },
  {
    name: 'Guinea',
    id: 'gn',
    code: '224',
    mask: ['00 000 000'],
  },
  {
    name: 'Guinea-Bissau',
    id: 'gw',
    code: '245',
    mask: ['0 000000'],
  },
  {
    name: 'Guyana',
    id: 'gy',
    code: '592',
    mask: ['000 0000'],
  },
  {
    name: 'Haiti',
    id: 'ht',
    code: '509',
    mask: ['00 00 0000'],
  },
  {
    name: 'Honduras',
    id: 'hn',
    code: '504',
    mask: ['0000 0000'],
  },
  {
    name: 'Hong Kong',
    id: 'hk',
    code: '852',
    mask: ['0000 0000'],
  },
  {
    name: 'Hungary',
    id: 'hu',
    code: '36',
    mask: ['(000)000 000'],
  },
  {
    name: 'Iceland',
    id: 'is',
    code: '354',
    mask: ['000 0000'],
  },
  {
    name: 'India',
    id: 'in',
    code: '91',
    mask: ['(0000)000 000'],
  },
  {
    name: 'Indonesia',
    id: 'id',
    code: '62',
    mask: ['(800)000 0000', '00 000 00', '00 000 000', '00 000 0000', '(800)000 000', '(800)000 00 000'],
  },
  {
    name: 'Iran',
    id: 'ir',
    code: '98',
    mask: ['(000)000 0000'],
  },
  {
    name: 'Iraq',
    id: 'iq',
    code: '964',
    mask: ['(000)000 0000'],
  },
  {
    name: 'Ireland',
    id: 'ie',
    code: '353',
    mask: ['(000)000 000'],
  },
  {
    name: 'Israel',
    id: 'il',
    code: '972',
    mask: ['50 000 0000', '0 000 0000'],
  },
  {
    name: 'Italy',
    id: 'it',
    code: '39',
    mask: ['(000)0000 000'],
  },
  {
    name: 'Jamaica',
    id: 'jm',
    code: '1876',
    mask: ['000 0000'],
  },
  {
    name: 'Japan',
    id: 'jp',
    code: '81',
    mask: ['00 0000 0000', '(000)000 000'],
  },
  {
    name: 'Jordan',
    id: 'jo',
    code: '962',
    mask: ['0 0000 0000'],
  },
  {
    name: 'Kazakhstan',
    id: 'kz',
    code: '7',
    mask: ['(600)000 00 00', '(700)000 00 00'],
  },
  {
    name: 'Kenya',
    id: 'ke',
    code: '254',
    mask: ['000 000000'],
  },
  {
    name: 'Kiribati',
    id: 'ki',
    code: '686',
    mask: ['00 000'],
  },
  {
    name: 'Kosovo',
    id: 'xk',
    code: '383',
    mask: [],
  },
  {
    name: 'Kuwait',
    id: 'kw',
    code: '965',
    mask: ['0000 0000'],
  },
  {
    name: 'Kyrgyzstan',
    id: 'kg',
    code: '996',
    mask: ['(000)000 000'],
  },
  {
    name: 'Laos',
    id: 'la',
    code: '856',
    mask: ['(2000)000 000', '00 000 000'],
  },
  {
    name: 'Latvia',
    id: 'lv',
    code: '371',
    mask: ['00 000 000'],
  },
  {
    name: 'Lebanon',
    id: 'lb',
    code: '961',
    mask: ['00 000 000', '0 000 000'],
  },
  {
    name: 'Lesotho',
    id: 'ls',
    code: '266',
    mask: ['0 000 0000'],
  },
  {
    name: 'Liberia',
    id: 'lr',
    code: '231',
    mask: ['00 000 000'],
  },
  {
    name: 'Libya',
    id: 'ly',
    code: '218',
    mask: ['00 000 000', '21 000 0000'],
  },
  {
    name: 'Liechtenstein',
    id: 'li',
    code: '423',
    mask: ['(000)000 0000'],
  },
  {
    name: 'Lithuania',
    id: 'lt',
    code: '370',
    mask: ['(000)00 000'],
  },
  {
    name: 'Luxembourg',
    id: 'lu',
    code: '352',
    mask: ['(000)000 000'],
  },
  {
    name: 'Macau',
    id: 'mo',
    code: '853',
    mask: ['0000 0000'],
  },
  {
    name: 'Macedonia',
    id: 'mk',
    code: '389',
    mask: ['00 000 000'],
  },
  {
    name: 'Madagascar',
    id: 'mg',
    code: '261',
    mask: ['00 00 00000'],
  },
  {
    name: 'Malawi',
    id: 'mw',
    code: '265',
    mask: ['1 000 000', '0 0000 0000'],
  },
  {
    name: 'Malaysia',
    id: 'my',
    code: '60',
    mask: ['00 000 0000', '(000)000 000', '00 000 000', '0 000 000'],
  },
  {
    name: 'Maldives',
    id: 'mv',
    code: '960',
    mask: ['000 0000'],
  },
  {
    name: 'Mali',
    id: 'ml',
    code: '223',
    mask: ['00 00 0000'],
  },
  {
    name: 'Malta',
    id: 'mt',
    code: '356',
    mask: ['0000 0000'],
  },
  {
    name: 'Marshall Islands',
    id: 'mh',
    code: '692',
    mask: ['000 0000'],
  },
  {
    name: 'Martinique',
    id: 'mq',
    code: '596',
    mask: ['(000)00 00 00'],
  },
  {
    name: 'Mauritania',
    id: 'mr',
    code: '222',
    mask: ['00 00 0000'],
  },
  {
    name: 'Mauritius',
    id: 'mu',
    code: '230',
    mask: ['000 0000'],
  },
  {
    name: 'Mexico',
    id: 'mx',
    code: '52',
    mask: ['(000)000 0000', '00 00 0000'],
  },
  {
    name: 'Micronesia',
    id: 'fm',
    code: '691',
    mask: ['000 0000'],
  },
  {
    name: 'Moldova',
    id: 'md',
    code: '373',
    mask: ['0000 0000'],
  },
  {
    name: 'Monaco',
    id: 'mc',
    code: '377',
    mask: ['(000)000 000', '00 000 000'],
  },
  {
    name: 'Mongolia',
    id: 'mn',
    code: '976',
    mask: ['00 00 0000'],
  },
  {
    name: 'Montenegro',
    id: 'me',
    code: '382',
    mask: ['00 000 000'],
  },
  {
    name: 'Morocco',
    id: 'ma',
    code: '212',
    mask: ['00 0000 000'],
  },
  {
    name: 'Mozambique',
    id: 'mz',
    code: '258',
    mask: ['00 000 000'],
  },
  {
    name: 'Myanmar',
    id: 'mm',
    code: '95',
    mask: ['00 000 000', '0 000 000', '000 000'],
  },
  {
    name: 'Namibia',
    id: 'na',
    code: '264',
    mask: ['00 000 0000'],
  },
  {
    name: 'Nauru',
    id: 'nr',
    code: '674',
    mask: ['000 0000'],
  },
  {
    name: 'Nepal',
    id: 'np',
    code: '977',
    mask: ['00 000 000'],
  },
  {
    name: 'Netherlands',
    id: 'nl',
    code: '31',
    mask: ['00 000 0000'],
  },
  {
    name: 'New Caledonia',
    id: 'nc',
    code: '687',
    mask: ['00 0000'],
  },
  {
    name: 'New Zealand',
    id: 'nz',
    code: '64',
    mask: ['(000)000 000', '00 000 000', '(000)000 0000'],
  },
  {
    name: 'Nicaragua',
    id: 'ni',
    code: '505',
    mask: ['0000 0000'],
  },
  {
    name: 'Niger',
    id: 'ne',
    code: '227',
    mask: ['00 00 0000'],
  },
  {
    name: 'Nigeria',
    id: 'ng',
    code: '234',
    mask: ['(000)000 0000', '00 000 000', '00 000 00', '(000)000 0000'],
  },
  {
    name: 'North Korea',
    id: 'kp',
    code: '850',
    mask: ['191 000 0000', '00 000 000', '000 0000 000', '000 000', '0000 0000', '0000 0000000000000'],
  },
  {
    name: 'Norway',
    id: 'no',
    code: '47',
    mask: ['(000)00 000'],
  },
  {
    name: 'Oman',
    id: 'om',
    code: '968',
    mask: ['00 000 000'],
  },
  {
    name: 'Pakistan',
    id: 'pk',
    code: '92',
    mask: ['(000)000 0000'],
  },
  {
    name: 'Palau',
    id: 'pw',
    code: '680',
    mask: ['000 0000'],
  },
  {
    name: 'Palestine',
    id: 'ps',
    code: '970',
    mask: ['00 000 0000'],
  },
  {
    name: 'Panama',
    id: 'pa',
    code: '507',
    mask: ['000 0000'],
  },
  {
    name: 'Papua New Guinea',
    id: 'pg',
    code: '675',
    mask: ['(000)00 000'],
  },
  {
    name: 'Paraguay',
    id: 'py',
    code: '595',
    mask: ['(000)000 000'],
  },
  {
    name: 'Peru',
    id: 'pe',
    code: '51',
    mask: ['(000)000 000'],
  },
  {
    name: 'Philippines',
    id: 'ph',
    code: '63',
    mask: ['(000)000 0000'],
  },
  {
    name: 'Poland',
    id: 'pl',
    code: '48',
    mask: ['(000)000 000'],
  },
  {
    name: 'Portugal',
    id: 'pt',
    code: '351',
    mask: ['00 000 0000'],
  },
  {
    name: 'Puerto Rico',
    id: 'pr',
    code: '1',
    mask: [],
  },
  {
    name: 'Qatar',
    id: 'qa',
    code: '974',
    mask: ['0000 0000'],
  },
  {
    name: 'Réunion',
    id: 're',
    code: '262',
    mask: ['00000 0000'],
  },
  {
    name: 'Romania',
    id: 'ro',
    code: '40',
    mask: ['00 000 0000'],
  },
  {
    name: 'Russia',
    id: 'ru',
    code: '7',
    mask: ['(000)000 00 00'],
  },
  {
    name: 'Rwanda',
    id: 'rw',
    code: '250',
    mask: ['(000)000 000'],
  },
  {
    name: 'Saint Kitts and Nevis',
    id: 'kn',
    code: '1869',
    mask: ['000 0000'],
  },
  {
    name: 'Saint Lucia',
    id: 'lc',
    code: '1758',
    mask: ['000 0000'],
  },
  {
    name: 'Saint Vincent and the Grenadines',
    id: 'vc',
    code: '1784',
    mask: ['000 0000'],
  },
  {
    name: 'Samoa',
    id: 'ws',
    code: '685',
    mask: ['00 0000'],
  },
  {
    name: 'San Marino',
    id: 'sm',
    code: '378',
    mask: ['0000 000000'],
  },
  {
    name: 'São Tomé and Príncipe',
    id: 'st',
    code: '239',
    mask: ['00 00000'],
  },
  {
    name: 'Saudi Arabia',
    id: 'sa',
    code: '966',
    mask: ['5 0000 0000', '0 000 0000'],
  },

  {
    name: 'Serbia',
    id: 'rs',
    code: '381',
    mask: ['00 000 0000'],
  },
  {
    name: 'Seychelles',
    id: 'sc',
    code: '248',
    mask: ['0 000 000'],
  },
  {
    name: 'Sierra Leone',
    id: 'sl',
    code: '232',
    mask: ['00 000000'],
  },
  {
    name: 'Singapore',
    id: 'sg',
    code: '65',
    mask: ['0000 0000'],
  },
  {
    name: 'Slovakia',
    id: 'sk',
    code: '421',
    mask: ['(000)000 000'],
  },
  {
    name: 'Slovenia',
    id: 'si',
    code: '386',
    mask: ['00 000 000'],
  },
  {
    name: 'Solomon Islands',
    id: 'sb',
    code: '677',
    mask: ['000 0000', '00000'],
  },
  {
    name: 'Somalia',
    id: 'so',
    code: '252',
    mask: ['00 000 000', '0 000 000', '0 000 000'],
  },
  {
    name: 'South Africa',
    id: 'za',
    code: '27',
    mask: ['00 000 0000'],
  },
  {
    name: 'South Korea',
    id: 'kr',
    code: '82',
    mask: ['00 000 0000'],
  },
  {
    name: 'South Sudan',
    id: 'ss',
    code: '211',
    mask: ['00 000 0000'],
  },
  {
    name: 'Spain',
    id: 'es',
    code: '34',
    mask: ['(000)000 000'],
  },
  {
    name: 'Sri Lanka',
    id: 'lk',
    code: '94',
    mask: ['00 000 0000'],
  },
  {
    name: 'Sudan',
    id: 'sd',
    code: '249',
    mask: ['00 000 0000'],
  },
  {
    name: 'Suriname',
    id: 'sr',
    code: '597',
    mask: ['000 0000', '000 000'],
  },
  {
    name: 'Swaziland',
    id: 'sz',
    code: '268',
    mask: ['00 00 0000'],
  },
  {
    name: 'Sweden',
    id: 'se',
    code: '46',
    mask: ['00 000 0000'],
  },
  {
    name: 'Switzerland',
    id: 'ch',
    code: '41',
    mask: ['00 000 0000'],
  },
  {
    name: 'Syria',
    id: 'sy',
    code: '963',
    mask: ['00 0000 000'],
  },
  {
    name: 'Taiwan',
    id: 'tw',
    code: '886',
    mask: ['0 0000 0000', '0000 0000'],
  },
  {
    name: 'Tajikistan',
    id: 'tj',
    code: '992',
    mask: ['00 000 0000'],
  },
  {
    name: 'Tanzania',
    id: 'tz',
    code: '255',
    mask: ['00 000 0000'],
  },
  {
    name: 'Thailand',
    id: 'th',
    code: '66',
    mask: ['00 000 0000', '00 000 000'],
  },
  {
    name: 'Timor-Leste',
    id: 'tl',
    code: '670',
    mask: ['000 0000', '770 00000', '780 00000'],
  },
  {
    name: 'Togo',
    id: 'tg',
    code: '228',
    mask: ['00 000 000'],
  },
  {
    name: 'Tonga',
    id: 'to',
    code: '676',
    mask: ['00000'],
  },
  {
    name: 'Trinidad and Tobago',
    id: 'tt',
    code: '1868',
    mask: ['000 0000'],
  },
  {
    name: 'Tunisia',
    id: 'tn',
    code: '216',
    mask: ['00 000 000'],
  },
  {
    name: 'Turkey',
    id: 'tr',
    code: '90',
    mask: ['(000)000 0000'],
  },
  {
    name: 'Turkmenistan',
    id: 'tm',
    code: '993',
    mask: ['0 000 0000'],
  },
  {
    name: 'Tuvalu',
    id: 'tv',
    code: '688',
    mask: ['900000', '20000'],
  },
  {
    name: 'Uganda',
    id: 'ug',
    code: '256',
    mask: ['(000)000 000'],
  },
  {
    name: 'Ukraine',
    id: 'ua',
    code: '380',
    mask: ['(00)000 00 00'],
  },
  {
    name: 'United Arab Emirates',
    id: 'ae',
    code: '971',
    mask: ['50 000 0000', '0 000 0000'],
  },
  {
    name: 'United Kingdom',
    id: 'gb',
    code: '44',
    mask: ['00 0000 0000'],
  },

  {
    name: 'Uruguay',
    id: 'uy',
    code: '598',
    mask: ['0 000 00 00'],
  },
  {
    name: 'Uzbekistan',
    id: 'uz',
    code: '998',
    mask: ['00 000 0000'],
  },
  {
    name: 'Vanuatu',
    id: 'vu',
    code: '678',
    mask: ['00 00000', '00000'],
  },
  {
    name: 'Vatican City',
    id: 'va',
    code: '39',
    mask: ['6 698 00000'],
  },
  {
    name: 'Venezuela',
    id: 've',
    code: '58',
    mask: ['(000)000 0000'],
  },
  {
    name: 'Vietnam',
    id: 'vn',
    code: '84',
    mask: ['00 0000 000', '(000)0000 000'],
  },
  {
    name: 'Yemen',
    id: 'ye',
    code: '967',
    mask: ['000 000 000', '0 000 000', '00 000 000'],
  },
  {
    name: 'Zambia',
    id: 'zm',
    code: '260',
    mask: ['00 000 0000'],
  },
  {
    name: 'Zimbabwe',
    id: 'zw',
    code: '263',
    mask: ['0 000000'],
  },
]
