import Axios, { InternalAxiosRequestConfig } from 'axios'

import { API_BASE_URL } from '@/config'
import { getApiErrorDetail, getApiErrorRequestPayload } from '@/utils/errors'
import { toast } from 'sonner'
import { captureException } from '@sentry/react'

function authRequestInterceptor(config: InternalAxiosRequestConfig) {
  if (config.headers) {
    config.headers.Accept = 'application/json'
  }
  return config
}

export const api = Axios.create({
  baseURL: API_BASE_URL,
})

api.interceptors.request.use(authRequestInterceptor)
api.interceptors.response.use(
  (response) => {
    return response.data
  },
  (_error) => {
    const error = getApiErrorDetail(_error)

    try {
      const requestPayload = getApiErrorRequestPayload(_error)
      captureException(_error, {
        level: 'error',
        extra: {
          url: _error?.config?.url,
          method: _error?.config?.method,
          description: 'API Error',
          error,
          payload: requestPayload,
          code: _error?.code,
          message: _error?.message,
          json: JSON.stringify(_error?.toJSON()),
        },
      })
    } catch (e) {
      console.log('Error capturing exception', e)
    }

    if (import.meta.env.MODE === 'development') {
      toast.error(
        <div className="w-full">
          <div className="text-red-500 font-bold mx-auto flex justify-center w-full">## DEVELOPMENT ONLY ##</div>
          <div>
            <pre className="whitespace-pre-wrap break-words">{JSON.stringify(error, null, 2)}</pre>
          </div>
        </div>,
        {
          duration: 60 * 1000,
        },
      )
    }

    return Promise.reject(_error)
  },
)
