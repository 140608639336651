import * as React from 'react'

import { ActionIcon, Container, Group, Stack, StackProps, Title } from '@mantine/core'
import { ArrowLeft2 } from 'iconsax-react'
import { Head } from '../seo'

type ContentLayoutProps = {
  children: React.ReactNode
  title: string
  pageTitle?: string
  backHref?: string
  backClickCallback?: () => void
} & StackProps

export const ContentLayout = ({
  children,
  title,
  pageTitle,
  backHref,
  backClickCallback,
  ...props
}: ContentLayoutProps) => {
  return (
    <>
      <Head title={title} />
      <Container size="xs" p={0} h={'100svh'} bg="#FAFAFA" className="flex flex-col">
        {pageTitle && (
          <Group bg="white" justify="center" p={16} pos="relative">
            {(backClickCallback || backClickCallback) && (
              <ActionIcon
                component={backHref ? 'a' : 'button'}
                target="_blank"
                href={backHref}
                onClick={backClickCallback}
                size={24}
                variant="subtle"
                color="baseDarkgray"
                className="absolute left-4">
                <ArrowLeft2 />
              </ActionIcon>
            )}
            <Title order={1} size={20} className="text-center">
              {pageTitle}
            </Title>
          </Group>
        )}
        <Stack p={16} pb={32} flex={'1'} {...props}>
          {children}
        </Stack>
      </Container>
    </>
  )
}
