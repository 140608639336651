import { SVGProps } from 'react'

const PharmacyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2 44H46"
      stroke="#0336FC"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity={0.4}
      d="M39.5605 44.0201V35.1001"
      stroke="#0336FC"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.6002 21.7803C37.1602 21.7803 35.2002 23.7403 35.2002 26.1803V30.7202C35.2002 33.1602 37.1602 35.1202 39.6002 35.1202C42.0402 35.1202 44.0002 33.1602 44.0002 30.7202V26.1803C44.0002 23.7403 42.0402 21.7803 39.6002 21.7803Z"
      stroke="#0336FC"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.2002 44V12.0601C4.2002 8.04006 6.2003 6.02002 10.1803 6.02002H22.6403C26.6203 6.02002 28.6002 8.04006 28.6002 12.0601V44"
      stroke="#0336FC"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity={0.4}
      d="M11.5996 16.5H21.4996"
      stroke="#0336FC"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity={0.4}
      d="M11.5996 24H21.4996"
      stroke="#0336FC"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity={0.4}
      d="M16.5 44V36.5"
      stroke="#0336FC"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default PharmacyIcon
