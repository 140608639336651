import { SVGProps } from 'react'

const TickCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M24 4C12.98 4 4 12.98 4 24C4 35.02 12.98 44 24 44C35.02 44 44 35.02 44 24C44 12.98 35.02 4 24 4ZM33.56 19.4L22.22 30.74C21.94 31.02 21.56 31.18 21.16 31.18C20.76 31.18 20.38 31.02 20.1 30.74L14.44 25.08C13.86 24.5 13.86 23.54 14.44 22.96C15.02 22.38 15.98 22.38 16.56 22.96L21.16 27.56L31.44 17.28C32.02 16.7 32.98 16.7 33.56 17.28C34.14 17.86 34.14 18.8 33.56 19.4Z"
      fill="#049559"
    />
  </svg>
)
export default TickCircleIcon
