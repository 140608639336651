import { api } from '@/lib/axios'
import { useMutation } from '@tanstack/react-query'

export const useMakePayment = () => {
  return useMutation({
    mutationKey: ['makePayment'],
    mutationFn: async ({
      isB2BPayout,
      token,
      claimId,
      merchantId,
      amount,
      phoneNumber,
    }: {
      isB2BPayout: boolean
      token: string
      claimId: string
      merchantId?: string
      amount: number
      phoneNumber?: string
      }) => {
      const url = isB2BPayout
        ? `/coverage/?token=${token}&merchant_id=${merchantId}&claim_id=${claimId}&is_b2b_payout=${isB2BPayout}`
        : `/coverage/?token=${token}&claim_id=${claimId}&is_b2b_payout=${isB2BPayout}`

      const payload = isB2BPayout
        ? {
            updated_total_amount: amount,
          }
        : {
            phone_number: phoneNumber,
            updated_total_amount: amount,
          }

      const data = await api.patch(url, payload)

      return data
    },
  })
}
