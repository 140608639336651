import LinkExpiredIcon from '@/components/icons/LinkExpiredIcon'
import { ContentLayout } from '@/components/layouts/content-layout'
import { WHATSAPP_NUMBER } from '@/config'
import { decodeTokenQueryOptions } from '@/features/token/api/decode-token'
import { Box, Button, Group, Stack, Text } from '@mantine/core'
import { QueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { LoaderFunctionArgs, redirect } from 'react-router-dom'
import * as Sentry from '@sentry/react'

export const tokenLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const token = params.token as string

    try {
      const result = await queryClient.ensureQueryData(decodeTokenQueryOptions(token))
      const user: Sentry.User = {
        claim_id: result.claim_id,
      }

      if (result.phone_number) {
        // We use id instead of phone_number because filtering by custom property is not possible in Sentry
        user.id = result.phone_number
      }

      Sentry.setUser(user)
      Sentry.setTag('phone_number', result.phone_number)
      Sentry.setTag('claim_id', result.claim_id)
      Sentry.setTag('token_id', result.token_id)
      return result
    } catch (error) {
      return redirect('/token-not-valid')
    }
  }

export const TokenNotValidRoute = () => {
  const { t } = useTranslation()

  return (
    <ContentLayout title="Link is expired!">
      <Stack my="auto" align="center">
        <Box>
          <LinkExpiredIcon />
        </Box>
        <Text mt={12} size="xl" fw={700}>
          {t('link_expired')}
        </Text>
        <Text mt={2} ta="center" size="sm" fw={500} className="text-balance">
          {t('link_expired_description')}
        </Text>
      </Stack>
      <Group mt="auto">
        <Button component="a" fullWidth href={`https://wa.me/${WHATSAPP_NUMBER}?text=%20`} target="_blank">
          Go Home
        </Button>
      </Group>
    </ContentLayout>
  )
}
