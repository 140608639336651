import { api } from '@/lib/axios'
import { QueryConfig } from '@/lib/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'
import { useParams, useSearchParams } from 'react-router-dom'
import { organizationDetailsSchema } from '../types'

export const getOrganizationDetails = async ({ providerUrl, token }: { providerUrl: string; token: string }) => {
  const data = await api.post(`/coverage/payment-qrcode?wave_qr_url=${providerUrl}&token=${token}`)

  return organizationDetailsSchema.parse(data)
}

export const getOrganizationDetailsQueryOptions = (providerUrl: string, token: string) => {
  return queryOptions({
    queryKey: ['organizationDetails', providerUrl, token],
    queryFn: () => getOrganizationDetails({ providerUrl, token }),
  })
}

export const useOrganizationDetails = ({
  queryConfig,
}: {
  queryConfig?: QueryConfig<typeof getOrganizationDetailsQueryOptions>
}) => {
  const token = useParams().token as string

  const [searchParams, setSearchParams] = useSearchParams()
  const providerUrl = searchParams.get('provider_url') as string

  return useQuery({
    ...getOrganizationDetailsQueryOptions(providerUrl, token),
    ...queryConfig,
  })
}
