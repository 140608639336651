import {
  Badge,
  Checkbox,
  Container,
  Divider,
  Group,
  NumberFormatter,
  NumberInput,
  Radio,
  Stack,
  Text,
  Title,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { usePaymentBreakdown } from './usePaymentBreakdown'
import { Controller, useForm } from 'react-hook-form'

const CASH_ADVANCE_LIMIT = 35_000

export const MakePaymentPlayground = () => {
  const { t } = useTranslation()

  const form = useForm({
    defaultValues: {
      coverageType: 'prescription',
      percentage: 80,
      cashAdvance: true,
      isDigitized: true,
      userInputAmount: 1020,
      claimTotal: 1000,
    },
  })

  const { register, control, watch } = form

  const cashAdvance = watch('cashAdvance')
  const isDigitized = watch('isDigitized')
  const claimTotal = watch('claimTotal')
  const percentage = watch('percentage')
  const userInputAmount = watch('userInputAmount')
  const coverageType = watch('coverageType')

  const margin = coverageType === 'prescription' ? 0.02 : coverageType === 'labwork' ? 0.05 : 0
  const totalInsuranceCoverageAmount = (claimTotal * (cashAdvance ? 100 : percentage)) / 100
  const approvedAmount = isDigitized ? totalInsuranceCoverageAmount + totalInsuranceCoverageAmount * margin : 0
  const maximumUserCanInput = claimTotal + claimTotal * margin

  const { coveredAmount, remainingAmount } = usePaymentBreakdown({
    percentage,
    cashAdvance,
    userInputAmount,
  })

  return (
    <Container py={12} maw={600}>
      <Title order={3}>Make Payment Playground</Title>
      <Stack mt={8}>
        <Text fw={700} td="underline" mb={-8}>
          Claim Details
        </Text>
        <Stack mb={12}>
          <Controller
            control={control}
            name="coverageType"
            render={({ field }) => (
              <Radio.Group {...field} name="coverageType" label="Coverage Type">
                <Group mt="xs">
                  <Radio value="prescription" label="Prescription" />
                  <Radio value="labwork" label="Labwork" />
                  <Radio value="consultation" label="Consultation" />
                </Group>
              </Radio.Group>
            )}
          />
          <Text size="xs">Margin: {margin * 100}%</Text>
        </Stack>

        <Checkbox label="Cash Advance" {...register('cashAdvance')} />
        <Checkbox label="Digitized" {...register('isDigitized')} />
        <Controller
          control={control}
          name="claimTotal"
          render={({ field }) => <NumberInput disabled={!isDigitized} {...field} label="Claim total" />}
        />
        {isDigitized && (
          <Stack gap={2}>
            <Text size="xs">
              <strong>Approved amount:</strong> {approvedAmount} {'=>'} {claimTotal}*{percentage / 100} + {claimTotal}*
              {percentage / 100}*{margin}
            </Text>
            <Text size="xs">
              <strong>Maximum user can input:</strong> {maximumUserCanInput}
            </Text>
          </Stack>
        )}

        <Controller
          control={control}
          name="percentage"
          render={({ field }) => <NumberInput {...field} max={100} min={0} step={10} label="Percentage" />}
        />
      </Stack>
      <Stack mt={24}>
        <Text fw={700} td="underline">
          User Input
        </Text>
        <Controller
          control={control}
          name="userInputAmount"
          render={({ field }) => (
            <NumberInput
              error={
                !isDigitized && userInputAmount > CASH_ADVANCE_LIMIT
                  ? `You exceed ${CASH_ADVANCE_LIMIT} F cash advance limit`
                  : isDigitized && maximumUserCanInput && userInputAmount > maximumUserCanInput
                    ? `You exceed ${maximumUserCanInput} F maximum user can input limit`
                    : null
              }
              {...field}
              label="Total Amount"
            />
          )}
        />
      </Stack>

      <Stack mt={36} gap={24}>
        <Text fw={700} mb={-8} td="underline">
          Payment Breakdown
        </Text>
        <Group justify="space-between">
          <Text size="sm">{t('merchant')}</Text>
          <Badge variant="outline">Organization Name</Badge>
        </Group>
        <Stack gap={12}>
          <Group justify="space-between">
            <Text size="sm">{t('coverage_rate')}</Text>
            <Badge color="black" variant="outline" className="!bg-white">
              {percentage}%
            </Badge>
          </Group>
          <Group justify="space-between">
            <Text size="sm">
              {t('sura_pays')} ({cashAdvance ? 100 : percentage}%)
            </Text>
            <Badge color="alerts-green" variant="outline">
              <NumberFormatter
                suffix=" F"
                value={coveredAmount}
                decimalScale={0}
                thousandSeparator
                className="font-bold"
              />
            </Badge>
          </Group>
        </Stack>
        <Divider variant="dashed" my={-8} />
        <Group wrap="nowrap" gap={28} justify="space-between">
          <Text size="sm" className="text-balance">
            {cashAdvance ? t('will_be_deducted_from_salary') : t('to_be_paid_on_site')} ({100 - percentage}%)
          </Text>
          <Badge color="alerts-red" variant="outline" className="shrink-0">
            <NumberFormatter suffix=" F" value={remainingAmount} decimalScale={0} thousandSeparator />
          </Badge>
        </Group>
      </Stack>
    </Container>
  )
}
