import { rawCountries } from '@/components/ui/phone-input/rawCountries'
import { cn } from '@/utils/cn'
import {
  Box,
  Combobox,
  Group,
  Highlight,
  Input,
  InputProps,
  rem,
  Text,
  UnstyledButton,
  useCombobox,
} from '@mantine/core'
import { useOs } from '@mantine/hooks'
import { ArrowDown2 } from 'iconsax-react'
import { AsYouType } from 'libphonenumber-js/min'
import examples from 'libphonenumber-js/mobile/examples'
import React, { useMemo, useRef, useState } from 'react'
import { IMaskInput } from 'react-imask'
import flagStyles from './flags.module.css'

const DEFAULT_COUNTRY_CODE = '221'

const asYouType = new AsYouType() as AsYouType & {
  formatter: {
    nationalNumberTemplate?: string
  }
  state: {
    callingCode?: string
    nationalSignificantNumber?: string
  }
}

export const getParsedPhoneNumber = (value: string) => {
  asYouType.reset()
  asYouType.input('+' + value)
  return [asYouType.state.callingCode ?? DEFAULT_COUNTRY_CODE, asYouType.state.nationalSignificantNumber ?? value]
}

const CountryOption = React.memo(({ item, search }: { item: (typeof rawCountries)[0]; search: string }) => (
  <Combobox.Option value={item.id} key={item.id}>
    <Group justify="space-between" wrap="nowrap">
      <Group gap={8} wrap="nowrap" className="flex-grow-0">
        <i className={cn(flagStyles['flag'], flagStyles[item.id])}></i>
        <Text className="text-sm">
          <Highlight highlight={search} size="sm">
            {item.name}
          </Highlight>
        </Text>
      </Group>
      <Text className="shrink-0 flex-grow-0 text-sm">
        <Highlight highlight={search} size="sm">
          {'+' + item.code}
        </Highlight>
      </Text>
    </Group>
  </Combobox.Option>
))

const CountryOptions = React.memo(({ search }: { search: string }) => {
  const filteredOptions = rawCountries.filter(
    (item) => item.name.toLowerCase().includes(search.toLowerCase()) || item.code.includes(search),
  )

  return (
    <>
      <Combobox.Options mah={250} style={{ overflowY: 'auto' }} className={flagStyles['phone-input']}>
        {filteredOptions.length > 0 ? (
          filteredOptions.map((item) => <CountryOption key={item.id} item={item} search={search} />)
        ) : (
          <Combobox.Empty>No country found.</Combobox.Empty>
        )}
      </Combobox.Options>
    </>
  )
})

export const PhoneInput = ({
  value = '',
  onChange,
  ...props
}: {
  value?: string
  onChange: (value: string, nationalNumber: string, countryCode: string) => void
} & Omit<InputProps, 'value' | 'onChange'> & { label?: string }) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const searchRef = useRef<HTMLInputElement>(null)

  const [search, setSearch] = useState('')

  const [countryCode, nationalNumberValue] = useMemo(() => {
    return getParsedPhoneNumber(value)
  }, [value])

  const selectedCountry = useMemo(() => {
    return rawCountries.find((el) => el.code === countryCode) || rawCountries[0]
  }, [countryCode])

  const inputMask = useMemo(() => {
    asYouType.reset()
    const examplePhoneNumber = examples?.[selectedCountry.id.toUpperCase() as keyof typeof examples]
    if (!examplePhoneNumber) return

    asYouType.input('+' + selectedCountry.code + examplePhoneNumber)
    const nationalNumberTemplate = asYouType.formatter.nationalNumberTemplate
    if (!nationalNumberTemplate) return

    return nationalNumberTemplate.replaceAll('x', '0')
  }, [selectedCountry])

  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption()

      inputRef.current?.focus()
      setSearch('')
    },
    onDropdownOpen: () => {
      combobox.focusSearchInput()
    },
  })

  return (
    <Input.Wrapper required={props.required} label={props.label ?? 'Phone number'} error={props.error}>
      <Input
        inputRef={inputRef}
        data-autofocus
        type="tel"
        component={IMaskInput}
        value={nationalNumberValue}
        mask={inputMask}
        styles={{
          input: {
            paddingLeft: rem(106),
          },
          section: {
            // zIndex: 1000,
          },
          wrapper: {
            '--section-icon-focus-color': 'var(--mantine-color-base-darkgray-6)',
          },
        }}
        leftSectionWidth={98}
        leftSection={
          <Box
            className={cn(
              flagStyles['phone-input'],
              'text-base-black-6 w-full h-full flex items-center px-2 justify-center border-0 border-r border-r-stroke-base-gray-0 border-solid pointer-events-auto',
            )}>
            <Combobox
              store={combobox}
              width={250}
              position="bottom-start"
              withArrow
              withinPortal={true}
              onOptionSubmit={(val) => {
                const selectedCountry = rawCountries.find((el) => el.id === val)
                if (!selectedCountry) return

                onChange(selectedCountry.code, '', selectedCountry.code)

                combobox.closeDropdown()
              }}>
              <Combobox.Target>
                <Group
                  component={UnstyledButton}
                  gap={4}
                  wrap="nowrap"
                  className="w-24 flex items-center justify-between"
                  onClick={() => {
                    combobox.toggleDropdown()
                  }}>
                  <i className={cn(flagStyles['flag'], flagStyles[selectedCountry?.id])}></i>
                  <Text fw={600} fz={14}>
                    +{selectedCountry?.code}
                  </Text>
                  <ArrowDown2 size={16} />
                </Group>
              </Combobox.Target>

              <Combobox.Dropdown>
                <Combobox.Search
                  ref={searchRef}
                  value={search}
                  onChange={(event) => setSearch(event.currentTarget.value)}
                  placeholder="Search country..."
                />
                <CountryOptions search={search} />
              </Combobox.Dropdown>
            </Combobox>
          </Box>
        }
        {...props}
        onAccept={(value, mask) => {
          const _value = selectedCountry.code + mask.unmaskedValue

          onChange(_value, mask.unmaskedValue, selectedCountry.code)
        }}
      />
    </Input.Wrapper>
  )
}
