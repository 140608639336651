import { App } from '@/app'
import React from 'react'
import ReactDOM from 'react-dom/client'
import './i18n.ts'
import './index.css'
import { initSentry } from './lib/sentry'

initSentry()

// Reload the page if there is a preload error
window.addEventListener('vite:preloadError', () => {
  window.location.reload()
})

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
