import { z } from 'zod'

export const organizationDetailsSchema = z.object({
  name: z.string(),
  m_id: z.string(),
})

export const makePaymentResponseSchema = z.object({
  transaction_receipt_image_url: z.string().nullable(),
})

export type MakePaymentResponse = z.infer<typeof makePaymentResponseSchema>
export type OrganizationDetails = z.infer<typeof organizationDetailsSchema>
