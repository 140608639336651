import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import * as React from 'react'

import { queryClient } from '@/lib/react-query'
import { cssVariableResolver, theme } from '@/theme'
import { MantineProvider } from '@mantine/core'
import { ModalsProvider } from '@mantine/modals'
import i18n from 'i18next'
import { Toaster } from 'sonner'

type AppProviderProps = {
  children: React.ReactNode
}

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Toaster
        position="top-left"
        style={
          {
            ['--width']: '450px',
          } as React.CSSProperties
        }
      />
      {import.meta.env.DEV && (
        <>
          <ReactQueryDevtools />
          <div className="fixed bottom-0 left-0">
            <button onClick={() => i18n.changeLanguage(i18n.language === 'en' ? 'fr' : 'en')}>Change language</button>
          </div>
        </>
      )}
      <MantineProvider theme={theme} cssVariablesResolver={cssVariableResolver}>
        <ModalsProvider>{children}</ModalsProvider>
      </MantineProvider>
    </QueryClientProvider>
  )
}
