import { useMemo } from 'react'

type UsePaymentBreakdownProps = {
  userInputAmount: number
  percentage: number
  cashAdvance: boolean
}

export const usePaymentBreakdown = ({ userInputAmount, percentage, cashAdvance }: UsePaymentBreakdownProps) => {
  const coveredAmount = useMemo(() => {
    if (cashAdvance) {
      return userInputAmount
    }

    return userInputAmount * (percentage / 100)
  }, [userInputAmount, cashAdvance, percentage])

  const remainingAmount = useMemo(() => {
    return userInputAmount - (userInputAmount * percentage) / 100
  }, [percentage, userInputAmount])

  return {
    coveredAmount,
    remainingAmount,
  }
}
