import {
  Badge,
  Button,
  CSSVariablesResolver,
  Card,
  Checkbox,
  Input,
  InputWrapper,
  Modal,
  Paper,
  PinInput,
  RadioGroup,
  Text,
  createTheme,
  defaultCssVariablesResolver,
  defaultVariantColorsResolver,
  rem,
} from '@mantine/core'
import { DateInput } from '@mantine/dates'
import { Calendar } from 'iconsax-react'
import { colors } from './colors'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import badgeStyles from './styles/badge.module.css'
import buttonStyles from './styles/button.module.css'
import cardStyles from './styles/card.module.css'
import checkboxStyles from './styles/checkbox.module.css'
import inputStyles from './styles/input.module.css'
import inputWrapperStyles from './styles/inputWrapper.module.css'
import modalStyles from './styles/modal.module.css'
import radioGroupStyles from './styles/radioGroup.module.css'
import textStyles from './styles/text.module.css'

dayjs.extend(customParseFormat)

export const theme = createTheme({
  fontFamily: 'Inter',
  colors: colors,
  primaryColor: 'base-blue',
  defaultRadius: 'md',
  black: 'var(--mantine-color-base-black-6)',
  components: {
    Button: Button.extend({
      classNames: {
        root: buttonStyles.root,
        section: buttonStyles.section,
      },
      defaultProps: {
        radius: 'md',
        size: 'md',
        color: 'base-blue',
      },
    }),
    Checkbox: Checkbox.extend({
      classNames: checkboxStyles,
      defaultProps: {
        radius: 'sm',
      },
    }),
    InputWrapper: InputWrapper.extend({
      classNames: inputWrapperStyles,
    }),
    Input: Input.extend({
      classNames: inputStyles,
    }),
    DateInput: DateInput.extend({
      defaultProps: {
        placeholder: 'dd/mm/yyyy',
        valueFormat: 'DD/MM/YYYY',
        rightSection: <Calendar />,
        rightSectionPointerEvents: 'none',
      },
    }),
    PinInput: PinInput.extend({
      classNames: {
        input: 'placeholder:!not-italic',
      },
    }),
    Modal: Modal.extend({
      classNames: modalStyles,
      defaultProps: {
        centered: true,
        transitionProps: {
          transition: {
            common: {
              transitionTimingFunction: 'cubic-bezier(.16,1,.3,1)',
            },
            in: {
              opacity: 1,
              transform: 'translateY(5px) scale(.97)',
            },
            out: {
              opacity: 0,
              transform: 'translateY(0) scale(1)',
            },
            transitionProperty: 'opacity, transform, scale',
          },
          duration: 200,
        },
      },
    }),
    Badge: Badge.extend({
      classNames: badgeStyles,
      defaultProps: {
        radius: 'sm',
        size: 'md',
        tt: 'none',
      },
    }),
    Paper: Paper.extend({
      defaultProps: {
        radius: rem(12),
      },
    }),
    Card: Card.extend({
      classNames: cardStyles,
    }),
    Text: Text.extend({
      classNames: textStyles,
    }),
    RadioGroup: RadioGroup.extend({
      classNames: radioGroupStyles,
    }),
  },
  variantColorResolver: (input) => {
    return defaultVariantColorsResolver(input)
  },
})

export const cssVariableResolver: CSSVariablesResolver = (theme) => ({
  ...defaultCssVariablesResolver(theme),
  light: {
    ...defaultCssVariablesResolver(theme).light,
    '--mantine-color-error': theme.colors['alerts-red'][6],
  },
})
