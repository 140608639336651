/* eslint-disable react-refresh/only-export-components */
import { AGENT_NUMBER } from '@/config'
import { theme } from '@/theme'
import { Button, Center, Divider, Group, Image, Modal, ModalProps, rem, Stack, Text } from '@mantine/core'
import { useOs } from '@mantine/hooks'
import { modals } from '@mantine/modals'
import { Camera, Gallery, TickCircle } from 'iconsax-react'
import { useState } from 'react'

const commonModalProps: Partial<ModalProps> = {
  closeOnClickOutside: false,
  withCloseButton: false,
  radius: 'lg',
  centered: false,
  transitionProps: {
    ...theme.components?.Modal?.defaultProps?.transitionProps,
    duration: 400,
  },
  styles: {
    body: {
      padding: 0,
    },
    header: {
      paddingBottom: 0,
    },
  },
  title: (
    <Center w={rem(40)} h={rem(40)} bg="alerts-yellow.0" className="rounded-full">
      <Camera size={24} color="var(--mantine-color-alerts-yellow-6)" />
    </Center>
  ),
}

export const PermissionDeniedModalContent = ({ onSubmit }: { onSubmit?: () => void }) => {
  const os = useOs({ getValueInEffect: false })
  const [howToEnableModalOpened, setHowToEnableModalOpened] = useState(false)

  const isIos = os === 'ios'

  return (
    <Stack className="overflow-hidden" gap={0}>
      {!isIos && (
        <Modal
          opened={howToEnableModalOpened}
          onClose={() => setHowToEnableModalOpened(false)}
          centered={false}
          title={
            <Center w={rem(40)} h={rem(40)} className="bg-[var(--mantine-color-alerts-green-light)] rounded-full">
              <TickCircle size={24} color="var(--mantine-color-alerts-green-filled)" />
            </Center>
          }
          radius="lg"
          yOffset={'10dvh'}
          zIndex={202}
          withOverlay={false}
          withCloseButton={false}
          keepMounted
          transitionProps={{
            duration: 400,
            exitDuration: 400,
          }}
          styles={{
            title: {
              marginInline: 'auto',
            },
            header: {
              paddingBottom: rem(8),
            },
            content: {
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              border: `1px solid var(--mantine-color-stroke-base-gray-0)`,
            },
            body: {
              padding: 0,
              display: 'flex',
              flexDirection: 'column',
              flexGrow: '1',
            },
          }}>
          <Stack flex={1} className="overflow-hidden">
            <Text fz="min(4.5vw, calc(0.04 * 33.75rem))" fw={600} ta="center" className="shrink-0">
              Comment autoriser l'accès à la caméra
            </Text>
            <Group flex={1} pos="relative" justify="center">
              <Image
                src="/android-camera-permission.gif"
                w="auto"
                h="100%"
                radius={rem(29)}
                mx="auto"
                flex={1}
                fit="contain"
                pos="absolute"
                bd="1px solid var(--mantine-color-stroke-base-gray-0)"
              />
            </Group>
            <Button
              variant="subtle"
              size="md"
              styles={{
                root: {
                  height: rem(48),
                  borderRadius: '0',
                  borderTop: `1px solid var(--mantine-color-stroke-base-gray-0)`,
                },
              }}
              onClick={() => {
                document.documentElement.style.setProperty('--camera-permission-modal-scale', '1')
                setHowToEnableModalOpened(false)
              }}>
              Fermer
            </Button>
          </Stack>
        </Modal>
      )}
      <Stack p={16} pt={8} gap={8}>
        <Text data-autofocus fz={18} fw={600}>
          Accès à la caméra refusé
        </Text>
        <Text fz={16} fw={500} c="base-darkgray">
          Nous détectons que vous n'avez pas autorisé l'accès à la caméra. Cliquez sur le bouton ci-dessous pour donner
          l'autorisation.
        </Text>
      </Stack>
      <Button
        variant="subtle"
        size="md"
        styles={{
          root: {
            height: rem(48),
            borderRadius: '0',
            borderTop: `1px solid var(--mantine-color-stroke-base-gray-0)`,
          },
        }}
        onClick={() => {
          if (isIos) {
            onSubmit?.()
          } else {
            setHowToEnableModalOpened(true)
            document.documentElement.style.setProperty('--camera-permission-modal-scale', '0.83')
          }
        }}>
        {isIos ? "Autoriser l'accès à la caméra" : 'Voir comment activer'}
      </Button>
    </Stack>
  )
}

export const openPermissionDeniedModal = ({ onSubmit }: { onSubmit?: () => void }) => {
  modals.open({
    ...commonModalProps,
    styles: {
      ...commonModalProps.styles,
      content: {
        scale: 'var(--camera-permission-modal-scale,1)',
      },
    },
    children: <PermissionDeniedModalContent onSubmit={onSubmit} />,
  })
}

export const openGenericCameraErrorModal = () => {
  modals.open({
    ...commonModalProps,
    children: (
      <Stack className="overflow-hidden" gap={0}>
        <Stack p={16} pt={8} gap={8}>
          <Text data-autofocus fz={18} fw={600}>
            Il y a un problème avec votre caméra.
          </Text>
          <Text fz={16} fw={500} c="base-darkgray">
            Nous détectons que nous ne pouvons pas accéder à votre caméra pour le moment. Veuillez vérifier les
            paramètres de la caméra de votre téléphone. Actualisez la page. Si le problème persiste, contactez-nous.
          </Text>
        </Stack>
        <Group
          style={{
            border: '1px solid var(--mantine-color-stroke-base-gray-0)',
          }}
          className="[&>*]:flex-1 [&>*]:rounded-none [&>*]:active:!transform-none "
          gap={0}>
          <Button
            component="a"
            href={`tel:${AGENT_NUMBER}`}
            target="_blank"
            variant="subtle"
            color="base-darkgray"
            fw={700}>
            Contactez-nous
          </Button>
          <Divider orientation="vertical" flex={0} />
          <Button
            variant="subtle"
            color="base-blue"
            fw={700}
            className="!rounded-none"
            onClick={() => window.location.reload()}>
            Actualiser la page
          </Button>
        </Group>
      </Stack>
    ),
  })
}

export const openCameraIssueSelectGalleryModal = ({ onSubmit }: { onSubmit: () => void }) => {
  modals.open({
    ...commonModalProps,
    children: (
      <Stack className="overflow-hidden" gap={0}>
        <Stack p={16} pt={8} gap={8}>
          <Text data-autofocus fz={18} fw={600}>
            Il y a un problème avec votre caméra.
          </Text>
          <Text fz={16} fw={500} c="base-darkgray">
            Nous détectons que nous ne pouvons pas accéder à votre caméra pour le moment. Veuillez vérifier les
            paramètres de la caméra de votre téléphone. Actualisez la page. Si le problème persiste, contactez-nous.
          </Text>
        </Stack>
        <Group
          style={{
            border: '1px solid var(--mantine-color-stroke-base-gray-0)',
          }}
          className="[&>*]:flex-1 [&>*]:rounded-none [&>*]:active:!transform-none "
          gap={0}>
          <Button
            variant="subtle"
            color="base-blue"
            fw={700}
            className="!rounded-none"
            leftSection={<Gallery />}
            onClick={onSubmit}>
            Sélectionner la Galerie
          </Button>
        </Group>
      </Stack>
    ),
  })
}

export const openFirstTimeCameraModal = ({ onSubmit }: { onSubmit: () => void }) => {
  modals.open({
    ...commonModalProps,
    centered: true,
    children: (
      <Stack className="overflow-hidden" gap={0}>
        <Stack p={16} pt={8} gap={8}>
          <Text data-autofocus fz={18} fw={600}>
            Nous avons besoin d'autorisation pour la caméra.
          </Text>
          <Text fz={16} fw={500} c="base-darkgray">
            Pour payer avec le code QR Wave, autorisez l'accès à votre caméra. Besoin d'aide ? Appelez le 200250 📞.
          </Text>
        </Stack>
        <Group
          style={{
            border: '1px solid var(--mantine-color-stroke-base-gray-0)',
          }}
          className="[&>*]:flex-1 [&>*]:rounded-none [&>*]:active:!transform-none "
          gap={0}>
          <Button variant="subtle" color="base-blue" fw={700} className="!rounded-none" onClick={onSubmit}>
            Continuer
          </Button>
        </Group>
      </Stack>
    ),
  })
}
