/* eslint-disable no-unused-vars */
export enum ErrorEnum {
  TRANSACTION_NOT_FOUND = 'TRANSACTION_NOT_FOUND',
  CLAIM_REJECTED_ALREADY = 'CLAIM_REJECTED_ALREADY',
  CLAIM_APPROVED_ALREADY = 'CLAIM_APPROVED_ALREADY',
  PRACTITIONER_NOT_FOUND = 'PRACTITIONER_NOT_FOUND',
  BENEFICIARY_NOT_FOUND = 'BENEFICIARY_NOT_FOUND',
  DEPENDENT_NOT_FOUND = 'DEPENDENT_NOT_FOUND',
  PRESCRIPTION_DETAILS_NOT_FOUND = 'PRESCRIPTION_DETAILS_NOT_FOUND',
  LABWORK_DETAILS_NOT_FOUND = 'LABWORK_DETAILS_NOT_FOUND',
  CONSULTATION_DETAILS_NOT_FOUND = 'CONSULTATION_DETAILS_NOT_FOUND',
  GENERAL_ERROR = 'GENERAL_ERROR',
  LAB_AUTHENTICITY_NOT_FOUND = 'LAB_AUTHENTICITY_NOT_FOUND',
  PRESCRIPTION_AUTHENTICITY_NOT_FOUND = 'PRESCRIPTION_AUTHENTICITY_NOT_FOUND',
  LAB_AUTHENTICITY_NOT_VERIFIED = 'LAB_AUTHENTICITY_NOT_VERIFIED',
  PRESCRIPTION_AUTHENTICITY_NOT_VERIFIED = 'PRESCRIPTION_AUTHENTICITY_NOT_VERIFIED',
  PRESCRIPTION_IMAGES_NOT_FOUND = 'PRESCRIPTION_IMAGES_NOT_FOUND',
  LAB_IMAGES_NOT_FOUND = 'LAB_IMAGES_NOT_FOUND',
  DATABASE_ERROR = 'DATABASE_ERROR',
  MERCHANT_ID_REQUIRED = 'MERCHANT_ID_REQUIRED',
  PAYOUT_NOT_FOUND = 'PAYOUT_NOT_FOUND',
  MERCHANT_DETAILS_NOT_FOUND = 'MERCHANT_DETAILS_NOT_FOUND',
  AMOUNT_EXCEEDS_LIMIT = 'AMOUNT_EXCEEDS_LIMIT',
  SIMILAR_TRANSACTION_ONGOING = 'SIMILAR_TRANSACTION_ONGOING',
  CLAIM_CANNOT_BE_PROCESSED = 'CLAIM_CANNOT_BE_PROCESSED',
  TOKEN_REVOKED = 'TOKEN_REVOKED',
  TOKEN_USED = 'TOKEN_USED',
  TOKEN_NOT_EXIST = 'TOKEN_NOT_EXIST',
  CEILING_AMOUNT_PER_ITEM_EXCEEDED = 'CEILING_AMOUNT_PER_ITEM_EXCEEDED',
  SPENDING_LIMIT_PER_ITEM_EXCEEDED = 'SPENDING_LIMIT_PER_ITEM_EXCEEDED',
  GLOBAL_SPENDING_LIMIT_EXCEEDED = 'GLOBAL_SPENDING_LIMIT_EXCEEDED',
  IMAGES_NOT_PRECRIPTION = 'IMAGES_NOT_PRECRIPTION',
  FILE_NOT_IMAGES = 'FILE_NOT_IMAGES',
  BENEFICIARY_SUSPENDED = 'BENEFICIARY_SUSPENDED',
  BENEFICIARY_HAS_NO_ACTIVE_POLICY = 'BENEFICIARY_HAS_NO_ACTIVE_POLICY',
  INVALID_UNAUTHORIZED_QRCODE = 'INVALID_UNAUTHORIZED_QRCODE',
  PHONE_NUMBER_EXIST = 'PHONE_NUMBER_EXIST',
  POLICY_NOT_FOUND = 'POLICY_NOT_FOUND',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  PHONE_OR_EMAIL_MISSING = 'PHONE_OR_EMAIL_MISSING',
  HAS_POLICY_ACTIVE = 'HAS_POLICY_ACTIVE',
  HAS_NO_POLICY_ACTIVE = 'HAS_NO_POLICY_ACTIVE',
  ORGANIZATION_NOT_FOUND = 'ORGANIZATION_NOT_FOUND',
  UNAUTHORIZED_ACTION = 'UNAUTHORIZED_ACTION',
  POLICY_EXISTS = 'POLICY_EXISTS',
  INVALID_RECEIPT_IMAGE = 'INVALID_RECEIPT_IMAGE',
  '4_NOT_VALID' = '4_NOT_VALID',
  '3_MULTIPLE_IMAGES_MULTIPLE_PRESCRIPTIONS' = '3_MULTIPLE_IMAGES_MULTIPLE_PRESCRIPTIONS',
}

import { AxiosError } from 'axios'

interface ApiErrorDetail {
  message: string
  error_code?: ErrorEnum
}

export interface ApiErrorResponse {
  detail: ApiErrorDetail | string
}

export type ApiError = AxiosError<ApiErrorResponse>

export function getApiErrorDetail(error: unknown): ApiErrorDetail {
  if (error && typeof error === 'object' && 'isAxiosError' in error) {
    const axiosError = error as AxiosError<ApiErrorResponse>
    if (axiosError.response?.data) {
      const { detail } = axiosError.response.data
      if (typeof detail === 'string') {
        return { message: detail, error_code: ErrorEnum.GENERAL_ERROR }
      } else if (typeof detail === 'object' && 'message' in detail) {
        return {
          message: detail.message,
          error_code: detail.error_code || ErrorEnum.GENERAL_ERROR,
        }
      }
    }
  }
  return { message: 'An unknown error occurred', error_code: ErrorEnum.GENERAL_ERROR }
}

export function getApiErrorRequestPayload(error: unknown): Record<string, string> {
  if (error && typeof error === 'object' && 'isAxiosError' in error) {
    const axiosError = error as AxiosError<ApiErrorResponse>
    if (axiosError.config?.data) {
      const configData = axiosError.config.data
      if (configData instanceof FormData) {
        return Object.fromEntries(configData.entries()) as Record<string, string>
      } else if (typeof configData === 'object' && configData !== null) {
        return configData as Record<string, string>
      } else if (typeof configData === 'string') {
        try {
          return JSON.parse(configData) as Record<string, string>
        } catch (e) {
          return { data: configData }
        }
      }
    }
  }
  return {}
}
