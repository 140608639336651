import { api } from '@/lib/axios'
import { queryOptions, useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { decodeTokenSchema } from '../types'

export const decodeToken = async ({ token }: { token: string }) => {
  const data = await api.get(`/coverage/token-status?token=${token}`)

  return decodeTokenSchema.parse(data)
}

export const decodeTokenQueryOptions = (token: string) => {
  return queryOptions({
    queryKey: ['decodeToken', token],
    queryFn: () => decodeToken({ token }),
  })
}

export const useDecodeToken = (_token?: string) => {
  const token = useParams().token as string
  return useQuery(decodeTokenQueryOptions(_token ?? token))
}
