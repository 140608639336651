import { SVGProps } from 'react'

const SuraWithLabelIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={107} height={45} viewBox="0 0 107 45" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M32.7535 44.1353C30.8695 44.1353 29.1518 43.9159 27.6003 43.4771C26.0488 43.0382 24.7328 42.4239 23.6523 41.634L25.2731 37.2459C26.3536 37.948 27.5449 38.5038 28.8471 38.9134C30.1769 39.3229 31.4929 39.5277 32.7951 39.5277C34.0141 39.5277 34.9145 39.3229 35.4963 38.9134C36.0781 38.5038 36.369 37.948 36.369 37.2459C36.369 36.6608 36.1751 36.2074 35.7872 35.8856C35.4271 35.5638 34.8591 35.3297 34.0833 35.1834L29.8029 34.3497C28.0021 33.9694 26.6307 33.2673 25.6887 32.2434C24.7467 31.2195 24.2757 29.8884 24.2757 28.2502C24.2757 26.8167 24.6359 25.5588 25.3562 24.4763C26.1043 23.3647 27.1709 22.5017 28.5562 21.8873C29.9414 21.273 31.5622 20.9658 33.4184 20.9658C34.9976 20.9658 36.4937 21.1706 37.9067 21.5802C39.3196 21.9897 40.5248 22.6187 41.5222 23.4671L39.8183 27.7236C38.9595 27.0507 37.9621 26.5095 36.8262 26.1C35.718 25.6904 34.6513 25.4856 33.6262 25.4856C32.2964 25.4856 31.3405 25.7197 30.7587 26.1877C30.1769 26.6265 29.886 27.197 29.886 27.8991C29.886 28.4257 30.0522 28.8645 30.3847 29.2156C30.7449 29.5666 31.2851 29.8299 32.0055 30.0054L36.3275 30.8392C38.1837 31.1902 39.5967 31.8485 40.5664 32.8138C41.536 33.7792 42.0209 35.1103 42.0209 36.8071C42.0209 38.3575 41.633 39.674 40.8573 40.7564C40.0815 41.8388 38.9872 42.6726 37.5742 43.2577C36.1889 43.8427 34.582 44.1353 32.7535 44.1353Z"
      fill="#0336FC"
    />
    <path
      d="M52.7389 44.1353C50.1069 44.1353 48.1537 43.3747 46.8792 41.8534C45.6048 40.303 44.9676 37.9626 44.9676 34.8324V21.4046H51.2428V34.8324C51.2428 36.2366 51.506 37.2751 52.0324 37.948C52.5865 38.6208 53.4315 38.9573 54.5674 38.9573C55.8142 38.9573 56.8393 38.5038 57.6427 37.5969C58.4739 36.6608 58.8895 35.4175 58.8895 33.867V21.4046H65.1647V43.6526H59.0557V39.3961H59.6375C59.0003 40.8881 58.086 42.0582 56.8947 42.9066C55.7311 43.7257 54.3458 44.1353 52.7389 44.1353Z"
      fill="#0336FC"
    />
    <path
      d="M69.4627 43.6526V27.2848C69.4627 26.3194 69.435 25.3394 69.3795 24.3447C69.3518 23.3501 69.2826 22.37 69.1718 21.4046H75.1976L75.9041 27.8991H74.9483C75.2253 26.3194 75.6686 25.0176 76.2781 23.9936C76.9154 22.9697 77.6911 22.2091 78.6054 21.7118C79.5474 21.2145 80.614 20.9658 81.8053 20.9658C82.3317 20.9658 82.7473 20.9951 83.0521 21.0536C83.3568 21.0828 83.6616 21.156 83.9663 21.273L83.9248 27.1092C83.343 26.846 82.8443 26.6851 82.4287 26.6265C82.0408 26.5388 81.5421 26.4949 80.9326 26.4949C79.769 26.4949 78.7993 26.7289 78.0236 27.197C77.2755 27.6651 76.7076 28.3526 76.3197 29.2594C75.9595 30.1663 75.7794 31.2634 75.7794 32.5506V43.6526H69.4627Z"
      fill="#0336FC"
    />
    <path
      d="M93.7428 44.1353C91.9143 44.1353 90.3074 43.6672 88.9221 42.7311C87.5368 41.7949 86.4563 40.4639 85.6806 38.7379C84.9048 37.0118 84.517 34.9348 84.517 32.5067C84.517 30.1078 84.9048 28.0454 85.6806 26.3194C86.4563 24.5934 87.5368 23.2769 88.9221 22.37C90.3074 21.4339 91.9143 20.9658 93.7428 20.9658C95.4328 20.9658 96.9428 21.4046 98.2726 22.2823C99.6302 23.1306 100.517 24.2862 100.932 25.7489H100.475L100.974 21.4046H107C106.917 22.37 106.834 23.3501 106.75 24.3447C106.695 25.3101 106.667 26.2609 106.667 27.197V43.6526H100.434L100.392 39.4838H100.891C100.447 40.8881 99.5609 42.0143 98.231 42.8627C96.9012 43.7111 95.4051 44.1353 93.7428 44.1353ZM95.6544 39.1328C97.0951 39.1328 98.2588 38.5916 99.1453 37.5092C100.032 36.4268 100.475 34.7593 100.475 32.5067C100.475 30.2541 100.032 28.6012 99.1453 27.5481C98.2588 26.4949 97.0951 25.9683 95.6544 25.9683C94.2138 25.9683 93.0502 26.4949 92.1636 27.5481C91.277 28.6012 90.8338 30.2541 90.8338 32.5067C90.8338 34.7593 91.2632 36.4268 92.122 37.5092C93.0086 38.5916 94.1861 39.1328 95.6544 39.1328Z"
      fill="#0336FC"
    />
    <path
      d="M35.011 18.386C34.486 18.344 33.955 18.323 33.419 18.323C31.687 18.323 30.049 18.555 28.54 19.062C31.712 15.089 32.657 13.632 33.085 12.383C33.215 12.007 33.227 11.855 33.221 10.903C33.215 9.74598 33.159 9.42998 32.771 8.44798C32.382 7.45398 31.55 6.38098 30.644 5.71398C30.151 5.34498 29.276 4.93798 28.635 4.77498C27.895 4.58098 26.884 4.58098 26.206 4.76898C24.529 5.24098 22.637 6.69598 20.972 8.79398C20.313 9.62398 19.721 9.91498 18.809 9.86098C18.315 9.82998 17.835 9.64198 17.49 9.35798C17.379 9.26698 17.126 8.98198 16.929 8.72698C15.437 6.80498 13.514 5.31998 11.874 4.81698C10.684 4.44698 9.37103 4.59898 8.10703 5.24098C6.06103 6.27798 4.72303 8.47898 4.70503 10.831C4.69903 11.613 4.85903 12.183 5.39503 13.249C6.32003 15.11 7.74403 17.135 10.931 21.124C12.842 23.518 18.901 30.787 18.963 30.769C18.987 30.762 19.875 29.689 20.935 28.392C21.161 28.115 21.406 27.814 21.659 27.503C21.642 27.75 21.634 27.999 21.634 28.25C21.634 30.047 22.072 31.732 23.052 33.163C23.03 33.19 23.009 33.216 22.988 33.242C22.174 34.242 21.41 35.139 21.299 35.23C20.781 35.66 19.764 36 18.987 36C18.161 36 17.181 35.66 16.639 35.194C16.312 34.909 10.265 27.725 7.70103 24.573C2.69503 18.42 0.716027 15.262 0.112027 12.48C-0.0229731 11.849 -0.0419731 9.97598 0.0880269 9.26698C0.310027 8.01198 0.667027 6.92098 1.21003 5.86598C2.80003 2.73798 5.67903 0.621978 9.06903 0.0829779C9.87703 -0.0450222 11.781 -0.00202215 12.49 0.161978C14.506 0.627978 16.553 1.77398 18.359 3.44698L18.957 4.00498L19.283 3.72598C19.456 3.56798 19.826 3.25298 20.097 3.01698C21.916 1.42798 23.95 0.415978 25.978 0.0829779C26.835 -0.0570221 28.511 -0.0140221 29.374 0.167978C34.084 1.18598 37.456 5.05998 37.949 10.024C38.165 12.183 37.702 14.128 36.488 16.159C36.062 16.875 35.653 17.509 35.011 18.386Z"
      fill="#049559"
    />
  </svg>
)
export default SuraWithLabelIcon
